import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AppContext, useAppStateContext } from 'unno-comutils'
import { ToastContainer } from 'unno-comutils/Dialog'
import { checkLogin, logout } from 'unno-comutils/connect'
import Wait from 'unno-comutils/Wait'

import Routing from './service/router'
import MainLayout from './layout'

import 'unno-comutils/style/core.css'
import './style/app.scss'
import { User } from 'unno-comutils/utils'

export const GMAP_LOAD: any = { libraries: ['geometry', 'places', 'visualization'], key: process.env.REACT_APP_GMAP, language: 'th' }

export default function App () {
    const [wait, setWait] = useState(true)
    const appState = useAppStateContext()

    useEffect(() => {
        setTimeout(() => {
            checkLogin()
                .then((d: any) => {
                    if (d.ok) {
                        appState.setUser(new User(d.user))
                    }
                    else {
                        appState.setUser(null)
                        logout()
                    }
                }).finally(() => setWait(false))
        }, 200)
    }, [])

    if (wait) return <Wait color={'blue'}/>
    return <AppContext.Provider value={appState}>
        <div className="layout">
            <BrowserRouter>
                {!!appState.user ? <MainLayout children={<Routing/>}/> : <Routing/>}
            </BrowserRouter>
            <ToastContainer/>
        </div>
    </AppContext.Provider>
}
